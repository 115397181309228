import React from 'react'
import './App.css';
import logo from "./assets/Banner1.png"

import instagram from "./assets/instagram.png"
import facebook from "./assets/facebookLogo.png"
import placeholder from "./assets/PlaceHolder.gif"
import { Link } from 'react-router-dom';
const email = 'deboucheaoreille@hotmail.com'; // Remplacez par votre adresse e-mail
const Adresse = "150 Route d'Ath, 7050 Jurbise";
const MAREF="https://www.facebook.com/waterloo1410"
export default function Acceuil() {
    return (
        <div className="accueil-container" style={{backgroundColor:"rgb(27, 27, 27)",height:"100vh"}}>
           <title>De Bouche a Oreille Jurbise - Accueil</title>
           <meta name="description" content="Restaurant De Bouche a Oreille. Cuisine aux saveurs traditionnelles françaises et de saison à Jurbise."/>
          <header className="app-header">
            <img src={logo} className="app-logo" alt="logo" />
          </header>
    
          <div className="content-container">
            <div className="presentation">
              {/*<img src={Resto3} className="presentation-image" alt="presentation" />*/}
              <div className="presentation-text">
                <h1 className="restaurant-title">Restaurant</h1>
                <p className="restaurant-description">
                  Forts de 18 années d'expérience dans la restauration, Isabelle et Benoit vous proposent une cuisine aux saveurs traditionnelles françaises et de saison dans un cadre chaleureux à Jurbise.
                </p>
                <p className="restaurant-description" style ={{fontsize: 17}}>
                  Lunch du marché 3 services à 30€ le jeudi et vendredi midi.
                </p>
                <div className="menu-links">
                  <nav>
                    <Link to="/Carte" className="Menu">
                      <span style={{fontSize:30}}>Carte</span>
                    </Link>
                    <Link to="/Galerie" className="Menu">
                      <span style={{fontSize:30}}>Galerie</span>
                    </Link>
                  </nav>
                </div>
                 
                <p className="restaurant-description" style ={{fontsize: 17}}>
                  <nav>
                    <Link to="/Menuspecial" className='Menu'>
                    <span className='Link'>Découvrez notre menu de Noël et Nouvel an, disponible le 25/12 et 01/01 midi.</span>
                    </Link>
                  </nav>
                </p>
              </div>
            </div>
    
            <div className="informations-container">
              <div className="informations">
                <div className="info-column">
                  <p className="info-title">Informations</p>
                  <p className="info-detail" style={{ textDecorationLine: 'underline' }}>
                    {email}
                  </p>
                  <p className="info-detail" style={{ textDecorationLine: 'underline' }}>
                    +32472838523
                  </p>
                  <p className="info-detail">{Adresse}</p>
                </div>
                <div className="info-column">
                  <p className="info-title">Horaires</p>
                  <p className="info-detail">Mercredi et Samedi <span className="highlight">soir</span></p>
                  <p className="info-detail">Jeudi et Vendredi <span className="highlight">midi</span>-<span className="highlight">soir</span>  </p>
                  <p className="info-detail">Dimanche <span className="highlight">midi</span></p>
                  <p className="info-detail">Lundi et Mardi <span className="highlight">fermé</span></p>
                </div>
              </div>
    
              <p className="follow-text">Suivez nous</p>
              <div className="social-links">
               
                <a href={MAREF} className="no-underline">
                  <img src={facebook} className="social" alt="facebook" />
                </a>
              </div>
            </div>
          </div>
        </div>
      );
}
