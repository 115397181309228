import './App.css';
import logo from "./assets/Banner8.png"
import instagram from "./assets/instagram.png"
import facebook from "./assets/facebookLogo.png"
import placeholder from "./assets/PlaceHolder.gif"
import { Link, Router } from 'react-router-dom';
import Acceuil from './Accueil';
import Carte from './Carte';
import Galerie from './Galerie';
import { Routes, Route, useLocation, Switch} from 'react-router-dom';
import MenuSpecial from './MenuSpecial';
import { useEffect } from 'react';

function usePageTitle() {
  const location = useLocation();
 useEffect(() => {
  
    switch (location.pathname) {
      case '/':
        document.title = 'De Bouche a Oreille - Accueil';
        break;
      case '/Carte':
        document.title = 'De Bouche a Oreille - Carte';
        break;
      case '/Galerie':
        document.title = 'De Bouche a Oreille - Galerie';
        break;
      case '/Menuspecial':
        document.title = 'De Bouche a Oreille - Noël et Nouvel An';
        break;
      default:
        document.title = 'Restaurant de Bouche a Oreille';
    }
  }, [location.pathname]);
}

function App() {
  usePageTitle();
  return (
   
    <Routes>
      
        <Route path="/" element={<Acceuil/>}></Route>
        <Route path="/Carte"  element={<Carte/>}/>
        <Route path="/Galerie" element={<Galerie/>}/>
        <Route path="/Menuspecial" element={<MenuSpecial/>}/>
        
      </Routes>
     
    
  );
}

export default App;
