import React from 'react'
import './App.css';
  

const HorizontalLine = ({ width, color }) => {
    const lineStyle = {
      width: width || '100%', 
      height: '1px',      
      backgroundColor: color || 'black', 
      margin: '10px 0',      
    };
  
    return <div style={lineStyle}></div>;
  };

function MenuSpecial() {
  return (
    <div style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/NoelBackground.jpg)`,
    height: '100vh', 
    width: '100%',    
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    overflowX: 'hidden'
    }}>
        <title>Noël et Nouvel an</title>
        <meta name="description" content="Menu de Noël et Nouvel an"/>

<div style={{
      flex: 1, // Prend tout l'espace restant disponible
      overflowY: 'auto', // Permet le défilement vertical
      padding: '20px', // Espacement interne (optionnel)
      width:"100%",
      alignItems:"center",
      justifyContent: 'center', 
      display:"flex"
      
     
    }}>
        <title>Menu de Noël</title>
    <div className="gallery" style={{justifyContent:'center', display:"flex", color:"black", fontFamily:"-moz-initial", fontSize:20, flexDirection:"column", alignItems:"center", width:"100%"}}>
   
     


     {/* MENU */}
     <p style={{ fontFamily:'fantasy',fontSize:28, textAlign:"center"}}>Menu de Noël et Nouvel an
        <br /><span style = {{fontSize:22}}>Servi le 25/12 et 01/01 midi </span>
        <br/><span style = {{fontSize:20}}>4 services 60€</span></p>
     
     <div style={{marginTop:-20, fontFamily:"-moz-initial", fontSize:20, alignItems:'center', justifyContent:"center", display:'flex', flexDirection:"column", maxWidth:"80%", textAlign:"center"}}>
     <p>Mises en bouche</p>
     <HorizontalLine width="20%"  />
     <p>Terrine de foie gras d'oie sur pain brioché</p>

     <HorizontalLine width="20%"  />
     <p>Cannellonis farcis aux écrevisses sur bisque de homard</p>
      {/*
     <HorizontalLine width="20%"  />

     <p>Foie gras d'oie poêlé aux raisins</p>
     
     <p style={{marginTop:-10, marginBottom:-10}}>OU</p>
     <p>Dos de cabillaud, pommes purée de brocolis beurre aux tomates cerises</p>*/}

     <HorizontalLine width="20%"  />
     <p>Filet pur de biche, crème de céleri rave, poire pochée au vin rouge</p>
     <p style={{marginTop:-10, marginBottom:-10}}>OU</p>
     <p>Poisson du jour garniture d'hiver</p>

     <HorizontalLine width="20%"  />
     <p>Millefeuille chocolat moka</p>

     </div>




    </div>
    </div>
    </div>
  )
}

export default MenuSpecial