import React from 'react'
import './App.css';

//import './assets/plat-1.jpg'
//import "./assets/plat-1.jpg"

export default function Galerie() {

    const images=[
        {url:"plat-1.jpg",
        des:"Carré d'agneau rôti en croûte d'herbes"},
        {url:"plat-13.jpg", des:"Cannellonis ricotta saumon fumé épinards"},
        {url:"plat-14.jpg",des:"Ris de veau céleri rave champignons sauvages"},
        {url:"plat-2.jpg",des:"Dos de cabillaud sur risotto et mousseline"},
        {url:"plat-3.jpg",des:"Pigeonneau d'Anjou rôti aux figues"},
        {url:"plat-4.jpg",des:"Tartare de boeuf aux saveurs asiatiques"},
        {url:"plat-5.jpg",des:"Meringue aux fruits rouges"},
        {url:"plat-6.jpg",des:"Cuisses de grenouille à l'ail"},
        {url:"plat-7.jpg",des:"Tartare de thon rouge"},
        {url:"plat-8.jpg",des:"Caille rôtie aux raisins"},
        {url:"plat-9.jpg",des:"Noix de st Jacques bretonne sur lentilles au curry"},
        {url:"plat-10.jpg",des:"Foie gras d'oie poêlé sur feuilleté"},
       
        
       
    ];

    return (
        <div className="origin">
        <div className="gallery-wrapper">
        <title>Galerie</title>
        <meta name="description" content="Galerie"/>
        <h1 style={{ fontFamily:"-moz-initial", fontSize:30, color:"white"}}>Galerie</h1>
        <br/>
        <div className="gallery">
       
          {images.map((image, index) => (
            <div key={index} className="gallery-item">
                
                <img className="img"src={`${process.env.PUBLIC_URL}/assets/${image.url}`} alt={`Photo ${index + 1}`} />
                <div>
                <p className="description">{image.des}</p>
                </div>
            </div>
          ))}
        </div>
        </div>
        </div>
      );
}
